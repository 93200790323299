<template>
  <v-container>
    <external-view v-if="this.$store.state.app.isAllowExternalLink" >
    </external-view >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="currentPlaylist.name"
        :counter="20"
        :rules="nameRules"
        :label="$vuetify.lang.translator('addPlaylist.name')"
        required
      ></v-text-field>

      <v-text-field
        v-model="currentPlaylist.address"
        :rules="addressRules"
        :label="$vuetify.lang.translator('addPlaylist.urlAddress')"
        required
      ></v-text-field>

      <v-select
        :hint="`${currentPlaylist.devices.name}, ${currentPlaylist.devices.id}`"
        v-model="currentPlaylist.devices"
        :items="$store.state.device.devices"
        item-text="name"
        item-value="id"
        :label="$vuetify.lang.translator('addPlaylist.forDevices')"
        multiple
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon
                :color="
                  currentPlaylist.devices.length > 0 ? 'indigo darken-4' : ''
                "
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{$vuetify.lang.translator('addPlaylist.selectAll')}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:append-item>
          <v-divider class="mb-2"></v-divider>
          <v-list-item disabled>
            <v-list-item-avatar color="grey lighten-3">
              <v-icon> mdi-television </v-icon>
            </v-list-item-avatar>

            <v-list-item-content v-if="likesAllFruit">
              <v-list-item-title>
                {{$vuetify.lang.translator('addPlaylist.playlistAllowOnAll')}}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-else-if="likesSomeFruit">
              <v-list-item-title>
                {{$vuetify.lang.translator('addPlaylist.playlistAllowOnSelected')}}
                </v-list-item-title
              >
              <v-list-item-subtitle>
                {{ currentPlaylist.devices.length }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title>
                {{$vuetify.lang.translator('addPlaylist.selectOneOrMore')}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$vuetify.lang.translator('addPlaylist.allowOnDevices')}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid || this.isLoading"
          color="success"
          class="mr-4"
          :loading="this.isLoading"
          @click="createPlaylist"
        >
          {{this.$vuetify.lang.translator('addPlaylist.save')}}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ExternalView from "@/components/ExternalView";

export default {
  name: "AddSource",
  components: {
    externalView: ExternalView
  },
  data() {
    return {
    valid: true,
    isLoading: false,
    currentPlaylist: {
      name: "",
      address: "",
      devices: [],
    },
    nameRules: [
      (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
      (v) => (v && v.length <= 20) || this.$vuetify.lang.translator("formMessages.mustBeLess", 20),
    ],
    addressRules: [
      (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
      (v) => /^(http:\/\/)|(https:\/\/)|(portal:).+/.test(v) || this.$vuetify.lang.translator("addPlaylist.incorrectUrl"),
      
      // (v) =>
      //   /.+((.m3u8)|(.m3u)|(.M3U8)|(.M3U))$/.test(v) ||
      //    this.$vuetify.lang.translator("addPlaylist.onlyM3UAllowed"),
    ],
    };
  },
  computed: {
    isValidForm() {
      return this.valid;
    },
    likesAllFruit() {
      return (
        this.currentPlaylist.devices.length ===
        this.$store.state.device.devices.length
      );
    },
    likesSomeFruit() {
      return this.currentPlaylist.devices.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.currentPlaylist.devices = [];
        } else {
          this.currentPlaylist.devices = this.$store.state.device.devices.map((element)=> element.id);
        }
      });
    },
    createPlaylist() {
      this.$refs.form.validate();
      if (!this.isValidForm) {
        return;
      }
      this.isLoading = true
      this.currentPlaylist.competion = (result) => {
        if (result) {
          this.clear();
        }
        this.isLoading = false
      };
      
      if (this.currentPlaylist.address.startsWith('portal::')){
        this.$store.dispatch("createVportal", this.currentPlaylist);
      }else{
        this.$store.dispatch("createPlaylist", this.currentPlaylist);
      }
    },
    clear() {
      this.currentPlaylist = {
        name: "",
        address: "",
        devices: [],
      };
      this.resetValidation();
    },
  },
  created() {
    this.$store.dispatch("getDevices");
  },
};
</script>
